/* Ensure the background remains transparent for the selected button */
.p-selectbutton .p-button.p-highlight {
    border: 0.15rem solid #7ab8ff; /* Add border with specific color */
    background-color: transparent; /* Ensure the background remains transparent */
    color: inherit; /* Inherit text color from the parent element */
}

.p-selectbutton .p-button.p-highlight:hover {
    border-color: #7ab8ff !important; /* Ensure the border color remains the same on hover */
}

.p-selectbutton .p-button.p-highlight:focus {
    box-shadow: none !important; /* Remove box-shadow on focus */
}

/* Remove default border, background, and box-shadow for normal buttons */
.p-selectbutton .p-button {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

/* Remove focus outline for normal buttons */
.p-selectbutton .p-button:focus {
    outline: none;
    box-shadow: none;
}
