.p-checkbox.p-highlight .p-checkbox-box {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
}
.custom-tree-container .p-checkbox-box .p-highlight {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
}

.p-tree .p-treenode .p-checkbox-box.p-highlight {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
  }

.p-checkbox .p-component .p-highlight {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-indeterminate .p-checkbox-icon {
  color: white !important;
}
